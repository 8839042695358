import React from 'react'
import { StyledHeader, Logo, MobileHeaderContent, DesktopHeaderContent } from './style';
import { HamburgerButton } from '../Buttons';
import logoSrc from '../../assets/images/icons/navi/logo.png'

function Header({ 
    toggleMenu, 
    toggleLightBox, 
    sceneData, 
    isVideoActive, 
    toggleMobileNavigation, 
    isLoading, 
    isMobileNavigationActive, 
    hasTourEnded,
    isMenuActive
}) {
    return (
        <StyledHeader>
            <DesktopHeaderContent isVideoActive={isVideoActive} showButton={!isLoading}>
                {
                    sceneData ?   
                        <>
                            <span></span>
                            <p>{sceneData.virtual[1]}</p>
                            <button onClick={() => toggleLightBox(sceneData.infoModal)}></button>
                        </>
                    : null
                }
            </DesktopHeaderContent>

            <MobileHeaderContent showButton={!isLoading} isSceneSelectorOpen={isMobileNavigationActive}>
                <button onClick={() => toggleMobileNavigation()}>select scene</button>
            </MobileHeaderContent>
            
            <Logo src={logoSrc} alt=""/>

            <HamburgerButton showButton={!isLoading && !hasTourEnded} isActive={isMenuActive} clickHandler={toggleMenu}/>
            {/* <HamburgerButton showButton={!isLoading && !hasTourEnded} isActive={isMenuActive} clickHandler={toggleMenu}/> */}

        </StyledHeader>
    );
}

export default Header;