import styled from 'styled-components'

const StyledLightBox = styled.div`
    width: 100%;
    max-width: 80%;
    padding: 1rem;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background: #fff;
    transition: all 0.3s ease-in-out; 
    text-align: center;
    visibility: ${props => props.isActive ? 'visible' : 'hidden'};
    opacity: ${props => props.isActive ? '1' : '0'};
    box-shadow: 0 0px 5px 1500px rgba(0, 0, 0, 0.75);

    @media (min-width: 900px) {
        width: 75%;
        padding: 2rem;
        max-width: 50%;
    }

    h1 {
        margin-top: 0;
        margin-bottom: 10px
    }

    p { margin: 0; }

    button {
        position: relative;
        border: none;
        width: 220px;
        margin-top: 20px;
        padding: 15px;
        letter-spacing: 1.5pt;
        font-size: 18px;
        text-align: center;
        background: linear-gradient(to right, #141414 0%, #272727 100%);
        color: #ffffff;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 0;
            width: 0;
            border-bottom: 13px solid #dfc645;
            border-right: 13px solid #ffffff;
        }
    }
`

export {
    StyledLightBox
}