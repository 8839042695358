import styled from 'styled-components'

const LoadingOverlay = styled.div`
    width: 100vw;
    // height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex; 
    background: rgba(0,0,0,0.5);
    z-index: 5;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;

    &:last-child  {
        margin-top: 15px;
    }

    h1 {
        font-family: "MinionPro-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 35px;
        line-height: 1.5em;
        font-weight: normal;
        margin: 0 0 15px;
        -webkit-font-smoothing: antialiased;

        @media (min-width: 900px) {
            font-size: 55px;
        }
    }

    p {
        margin-top: 15px;
        margin-bottom: 0;
    }

    button {
        position: relative;
        border: none;
        width: 220px;
        margin-top: 25px;
        padding: 15px;
        letter-spacing: 1.5pt;
        font-size: 18px;
        text-align: center;
        background: #ffffff;
        color: #141414;
        cursor: pointer;
        margin-left: auto; 
        margin-right: auto;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 0;
            width: 0;
            border-bottom: 13px solid #dfc645;
            border-right: 13px solid rgba(0,0,0);
        }
    }
`

export { LoadingOverlay }