import React, { useEffect, useState } from 'react'
import { LoadingOverlay } from './style'
import Ship from '../../assets/images/icons/ship.png'

function Loading({handleLoading}) {

    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        setTimeout(() => setIsReady(true), 5000)
    })

    return <LoadingOverlay>
        <span>Welcome Aboard</span>
        <h1>Formidable-class Frigate</h1>
        <img src={Ship} alt="" />
        {
            isReady
            ? <button onClick={() => handleLoading()}>Start</button>
            : <p>Loading</p>
        }
            
    </LoadingOverlay>
}

export default Loading;