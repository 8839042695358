
// import
// import { path } from '../variables/settings';

// social data
export const socialData = {

    copy: "Find us!",
    base: "./assets/images/icons/social/",
    links: [
        ["facebook.png", "https://www.facebook.com/singaporenavy"],
        ["instagram.png", "https://www.instagram.com/singaporenavy/"],
        ["youtube.png", "https://www.youtube.com/playlist?list=PLbOSqJKglMt2IC4Nn8M9_t7ijnY4UhJaR"]
    ],
};

// menu data
export const menuData = [

    /*
        {
            title: menu header,
            base: base url,
            links: [
                [ item name, item url, additional classes ],
            ]
        }
    */

    { // the RSN
        title: "The RSN",
        base: "/about/",
        links: [
            [ "Mission", "mission" ],
            [ "Our Values", "core-values" ],
            [ "Our Organisation Structure", "organisational-structure" ],
            [ "Our History", "history-and-heritage" ],
            [ "Navy Museum", "navy-museum" ],
            [ "Contact Us", "contact-us" ]
        ]
    },
    { // Assets
        title: "Assets",
        base: "/assets/",
        links: [
            [ "Ships", "ships" ],
            [ "Submarines", "submarines" ],
            [ "Weapons", "weapons" ],
            [ "Unmanned Naval Assets", "unmanned-naval-assets" ],
            [ "Aircrafts", "aircraft" ],
            [ "Naval Bases", "naval-bases" ]
        ]
    },
    { // Careers
        title: "Careers",
        base: "/careers/",
        links: [
            [ "Our Vocations", "vocations" ],
                [ "Naval Officer", "vocations/naval-officer", "sub" ],
                [ "Naval Warfare System Engineer", "vocations/naval-warfare-system-engineer", "sub" ],
                [ "Naval Warfare System Expert", "vocations/naval-warfare-system-experts", "sub" ],
                [ "Naval Diver", "vocations/naval-diver", "sub" ],
                [ "Military Medical Expert", "vocations/military-medical-expert", "sub" ],
                [ "Naval Chef", "vocations/naval-chef", "sub" ],
            [ "Scholarships", "scholarships" ],
            [ "Ranks / badges", "ranks-and-badges" ],
            [ "Medals", "medals" ],
            [ "Contact a Recruiter", "contact-recruiter" ]
        ]
    },
    { // Life in the navy
        title: "Life in the Navy",
        base: "/navy-life/",
        links: [
            [ "Feature Stories", "feature-stories" ],
            [ "Inside the Littoral Mission Vessel", "inside-lmv" ],
            [ "Stories from beyond", "stories-from-beyond" ],
            [ "Naval Traditions", "naval-traditions" ],
            [ "Virtual Tours", "virtual-tour" ]
        ]
    },
    { // news and events
        title: "News & Events",
        base: "/",
        links: [
            [ "Pressroom", "pressroom" ],
            // [ "Campaigns", "campaigns" ]
        ]
    },

    { // downloads
        title: "Downloads",
        base: "/downloads/",
        links: [
            [ "Gallery", "gallery" ],
            [ "Navy Magazines E-books", "magazines-and-e-books" ]
        ]
    }

];
