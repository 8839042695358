import styled from 'styled-components'

const SceneSelector = styled.div`
    display: block;

    @media (min-width: 900px) {
        display: none;
    }

    padding-top: 57px;
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    width: 100vw;
    // height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    transition: all 0.75s ease-in-out; 
    opacity: ${props => props.isMobileNavigationActive ? '1' : '0'};
    z-index: ${props => props.isMobileNavigationActive ? '8' : '-2'};

    ul {
        list-style: none;
        margin: auto;
        padding: 0;
        overflow-y: scroll;
        // height: calc(100vh - 57px);
        height: calc(var(--vh, 1vh) * 100 - 57px);
        min-height: 0;
    }
`

const Tile = styled.li`
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;

    /*
    ${props => props.isActive 
        ? `&:after {
            content: '';
            background-color: #dfc645;
            width: 100%;
            // transform: translateX(50%);
            height: 4px;
            position: absolute; 
            bottom: 0;
            left: 0;
        }`
        : null
    }
    */
    img {
        width: 100%;
        object-fit: cover;
    }

    span {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        width: 100%;
        text-align: center;
    }
`

export { SceneSelector, Tile };