import styled from 'styled-components'

const SceneSelector = styled.div`
    display: none;

    @media (min-width: 900px) {
        display: flex;
    }

    position: absolute;
    width: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    transition: all 0.75s ease-in-out; 
    transform: ${props => props.isNavigationActive ? 'translateY(0%)' : 'translateY(60%)'};
    
    p {
        text-align: center; 
        text-transform: uppercase;
        width: fit-content; 
        font-weight: bold; 
        font-size: 1.1rem;
        letter-spacing: 0.1pt;
        position: relative;
        color: #FFFFFF;
        cursor: pointer;

        &:after {
            position: absolute;
            top: 4px;
            right: -2.5rem;
            transition: transform 0.2s ease 0.2s;
            transform: ${props => props.isNavigationActive ? 'rotateX(180deg)' : 'rotateX(0deg)'};
            content: '';
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAANCAYAAABcrsXuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjQyNkFFNzJGOTMzMTFFNkJEMUU5RTFENkQzMDhCNkYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjQyNkFFNzNGOTMzMTFFNkJEMUU5RTFENkQzMDhCNkYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyNDI2QUU3MEY5MzMxMUU2QkQxRTlFMUQ2RDMwOEI2RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyNDI2QUU3MUY5MzMxMUU2QkQxRTlFMUQ2RDMwOEI2RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsQ0stYAAAIESURBVHjadJTPK2ZRGMfPcxFGaRb+kElsECVNDamhWYiSLESysFFSymLekrKwmCZZSIQMG2Jh8RY7xYpSFppSFrKQhSx0fM59z7nn3BP3fb/3+XGe+5zn+Z4fcnncr0QppYGT7sn5NZrIN/RlrfSgKLlWQXycQ4I8iUsQSsmCtZ9YpImAIlojximyPsiTSyyRP3GVpBOIZLq2Efbj74hjxr9in+OtI6ZIVGucUNtfyEbaSZrcTeBsG8H/F759rC/IGewGqCowUIs8wt8Vdm8S4LcT2k7UB1xqX9ow+hatleMfx1GwIzNETiGrwR4xfdrTmqcMVixdOuM/CJjkgxXDHZ0NEvcnrJCYBeQoKMNcByP5NSplNqwkfl4JOFVzqIt4X4nqRd8wFbmNEeyqZTAA3vD8ZWzKM+PpT/LLlq7CEq9Z8IzdCfbTaalIJCwpe7ZBDyEvyHlQUNFRSPw+F9P2Kh9P4HsEHaDo0kYd+K5K8pDXD4p5wjGtUmrtpnKdoFTy8T9Uw/09sg3fmfrkiXdgiX85Ae2U84BnDMcaqCjRpXUNygH6T+JvQTO4UsFJz98EOprSH13eFyit2HdEDYBd7KqEinZQOshmEjczcJsdxrBiv19UfJ1EmrluWsANnm7kTjmv6XR3iAwhH9OaRHwXkl8VHdGmPr7z/puOKGsT6/e7AAMAyavRQC2Q/NIAAAAASUVORK5CYII=);
            background-size: contain;
            background-repeat: no-repeat;
            width: 23px;
            height: 11px;
        }
    }

    ul {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        margin: auto;
        padding: 0;
    }
`

const Tile = styled.li`
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    box-sizing: border-box;

    ${props => props.isActive 
        ? `&:after {
            content: '';
            background-color: #dfc645;
            width: 100%;
            // transform: translateX(50%);
            height: 4px;
            position: absolute; 
            bottom: 0;
            left: 0;
        }`
        : null
    }
    
    img {
        width: 130px;
        object-fit: cover;
    }

    span {
        color: #fff;
        position: absolute;
        left: 50%;
        bottom: 5px;
        transform: translateX(-50%);
        font-size: 14px;
        width: 100%;
        text-align: center;
    }

    &:last-child {
        margin-right: 0;
    }
`

export { SceneSelector, Tile };