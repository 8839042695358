import styled from 'styled-components'

const OrientationWarningWrapper = styled.div`
    position: fixed; 
    // height: 100vh; 
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw; 
    display: flex; 
    justify-content: center; 
    align-content: center;
    z-index: 11; 
    background: #666;
    flex-direction: column;
    text-align: center; 
    opacity: ${props => props.isVisible ? 1: 0}; 
    visibility: ${props => props.isVisible ? 'visible': 'hidden'} ; 
    transition: all 0.4s ease-in-out;

    h1, p {
        color: white;
        margin: 0 0 10px 0;
    }
    p {
        font-size: 18px;
        line-height: 1.5em;
        letter-spacing: 1.5pt;
    }

    img {
        margin-top: 25px;
        width: 125px;
        margin-left: auto;
        margin-right: auto;
    }

    button {
        position: relative;
        border: none;
        width: 220px;
        margin-top: 25px;
        padding: 15px;
        letter-spacing: 1.5pt;
        font-size: 18px;
        text-align: center;
        background: linear-gradient(to right, #141414 0%, #272727 100%);
        color: #ffffff;
        cursor: pointer;
        margin-left: auto; 
        margin-right: auto;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 0;
            width: 0;
            border-bottom: 13px solid #dfc645;
            border-right: 13px solid #666666;
        }
    }
`

export {
    OrientationWarningWrapper
}