import React from 'react';
import { LightBoxWrapper, LightBoxContent } from './style';

const LightBox = ({data, toggleLightBox, isLightBoxActive}) => {
    return (
        <LightBoxWrapper isLightBoxActive={isLightBoxActive}> 
            <button onClick={() => toggleLightBox('')}></button>
            { data[0] ? <h1>{data[0]}</h1> : null }
            <LightBoxContent>
                <div className={`lightbox__inner ${!data[0] ? '_r' : ''}`} dangerouslySetInnerHTML={{ __html: data[1]}} />
            </LightBoxContent>
        </LightBoxWrapper>
    )
}

export default LightBox;
