import styled from 'styled-components'

const MegaMenuWrapper = styled.div`
    align-items: center;
    flex-direction: column; 
    background: rgba(0, 0, 0, 0.85);
    position: fixed;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 7;
    color: #ffffff;
    padding: 6rem 1rem 1rem 1rem;
    font-family: "Avenir-Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    justify-content: center;
    top: 0; 
    left: 0;
    transition: all 0.3s ease-in-out; 
    visibility: ${props => props.isMegaMenuActive ? 'visible' : 'hidden'};
    opacity: ${props => props.isMegaMenuActive ? '1' : '0'};

    p {
        text-align: center;
        letter-spacing: 1pt;
        margin: 0;

        :after {
            content: "";
            height: 2px;
            width: 80px;
            margin: 15px auto 25px;
            background-color: #cdbb5e;
            display: block;
        }
    }
`
const SocialLinksWrapper = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: center;
    margin-bottom: 2rem;

    a {
        width: 1.5rem;
        margin: 0 1.2rem;
    }

    img {
        width: 100%;
    }
`

const MenuWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1280px;
    margin: auto;
`

const Column = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    border-right: 1px dashed #ffffff;
    padding: 0 1rem;

    &:last-child {
        border-right: none;
    }
`

const Header = styled.a`
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-family: "MinionPro-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.1rem;
`

const StyledLinks = styled.ul`
    padding: 0;
    margin-top: 0;
    
    li {
        list-style: none;
        margin: 0.5rem 0;
    }

    a {
        color: #ffffff;
        text-decoration: none; 
        font-size: 0.85rem;

        &.sub {
            margin-left: 1rem;
            display: flex;
        }

        &.new:after {
            content: 'new';
            right: 20px;
            color: #cdbb5e;
            font-size: 0.7em;
            padding-left: 10px;
        }

        &:hover {
            color: #fed136;
        }
    }
`

export {
    MegaMenuWrapper,
    SocialLinksWrapper,
    MenuWrapper,
    Column,
    Header,
    StyledLinks,
}