import React, { useState } from 'react'
import { StyledDrawer } from './style'

function Drawer(props) {
    const [isActive, setIsActive] = useState(false);
    return (
        <StyledDrawer show={!props.isVideoActive}
            onMouseEnter={() => setIsActive(!isActive)} 
            onMouseLeave={() => setIsActive(!isActive)} 
            isActive={isActive}
            iconType={props.iconType}
            >
            <div>
                {props.children}
            </div>
        </StyledDrawer>
    )
}

export default Drawer