/* eslint-disable */
// import
// import { path, isMobile } from '../variables/settings';

const path = '';
const isMobile = window.innerWidth < 900;

// fetch story board
export default function fetchStoryBoard() {

    let sL = `${path}sounds/`, // sound location
        lL = `${path}locations${
            isMobile ? '/mobile/' : '/'
        }`, // 360 location
        cL = `${path}cutscenes${
            isMobile ? '/mobile/' : '/'
        }`, // cutscene location ${path}cutscenes/ // http://172.104.33.4/_v_/
        tB = `${path}images/ss${
            isMobile ? '/mobile/' : '/'
		}`;
		
    // thumbnails
    // lL = `${path}locations/`, // 360 location
    // cL = `${path}cutscenes/`, // cutscene location ${path}cutscenes/ // http://172.104.33.4/_v_/
    // tB = `${path}images/ss/`; // thumbnails


    /* // legend
		sceneID: {
			virtualLoader: [ loading screen subtitle, loading screen title ],
			virtual: [ location of 360 image, top left title ],
			hotspots: [
				{
					id: unique id (must be same as lightbox id),
					action: icon type (video, info, image),
					position: {x: position x, y: position y, z: position z},
					rotation: {y: rotation y},
					scale: scale,
				}
			],
			sound: [ link to backgound sound of scene ]
			video: [ video url , preloading (bool), top left title, { time: trigger time, shapes: [drawing shapes]} // action leave empty is nothing]
		}
	*/

    return [
        // Main Passageway
        {
            video: [
                `${cL}cutscene-0.mp4`, false, 'Main Passageway'
            ],
            videoLoader: [
                'Welcome Aboard', 'Main Passageway'
            ],
            virtualLoader: [
                'Welcome Aboard', 'Main Passageway'
            ],
            virtual: [
                `${lL}texture1.jpg`, 'Main Passageway'
            ],
            infoModal: 'mainPassageway',
            hotspots: [
                {
                    id: 'gunBay',
                    action: 'img',
                    position: {
                        x: -8.596308726474966,
                        y: -10.11829520213394,
                        z: -460.9383772181325
                    },
                    rotation: {
                        y: 3
                    },
                    scale: 80
                }, {
                    id: 'medicalCentre',
                    action: 'img',
                    position: {
                        x: -493.8930740251886,
                        y: -45.40556289389647,
                        z: -19.123228167852403
                    },
                    rotation: {
                        z: -1.5
                    },
                    scale: 110
                }, {
                    id: 'activeLowFrequencyTowedSonar',
                    action: 'img',
                    position: {
                        x: -10.99734549598158,
                        y: -60.7065130353342,
                        z: 490.07626921363516
                    },
                    rotation: {
                        z: 3.14
                    },
                    scale: 100
                }, {
                    id: 'machineryControlRoom',
                    action: 'img',
                    position: isMobile ? {
                        x: -57.5,
                        y: -35.39972503674977,
                        z: -459.8861117657475
                    } : {
                        x: -47.1640571637488,
                        y: -25.39972503674977,
                        z: -459.8861117657475
                    },
                    rotation: {
                        y: 3
                    },
                    scale: 90
                },
            ],
            // sound: [`${sL}outer.mp3`],
            thumb: [`${tB}1.jpg`, 'Main Passageway']
        },
        // hangar/helipad
        {
            // video: [`${cL}cutscene-1.mp4`, true, 'Hangar', { time: 10, shapes: ['dragUp2'] }],
            video: [
                `${cL}cutscene-1.mp4`, true, 'Hangar'
            ],
            videoLoader: [
                'Home of the sailors', 'Hangar'
            ],
            virtualLoader: [
                'Home of the sailors', 'Hangar'
            ],
            virtual: [
                `${lL}texture2.jpg`, 'Hangar'
            ],
            infoModal: 'helipad',
            hotspots: [
                {
                    id: 'helicopterControlRoom​',
                    action: 'img',
                    position: {
                        x: -225.0433187853188,
                        y: -15,
                        z: -415.6958406068171
                    },
                    rotation: {
                        y: 16
                    },
                    scale: 170
                },
                {
                    id: 'helicopterLandingAids​',
                    action: 'img',
                    position: {
                        x: -255.02544883677828,
                        y: 300,
                        z: 0
                    },
                    rotation: {
                        y: -14,
                        z: 4.7
                    },
                    scale: 180
                },
                {
                    id: 'rapidSecuringDevice​',
                    action: 'img',
                    position: {
                        x: 480,
                        y: -80,
                        z: -20
                    },
                    rotation: {
                        y: 1.5,
                        z: 1.5
                    },
                    scale: 110
                },
                {
                    id: 'seahawkNavalHelicopter​',
                    action: 'img',
                    position: {
                        x: -480,
                        y: 50,
                        z: -20
                    },
                    rotation: {
                        y: -14,
                        z: 4.7
                    },
                    scale: 110
                }, {
                    id: 'hangarDoor​',
                    action: 'img',
                    position: {
                        x: -310,
                        y: 75,
                        z: 350
                    },
                    rotation: {
                        y: -14,
                        z: 4
                    },
                    scale: 170
                }
            ],
            // sound: [`${sL}inner.mp3`],
            thumb: [`${tB}2.jpg`, 'Hangar']
        },
        // SSM deck
        {
            video: [
                `${cL}cutscene-2.mp4`, true, 'SSM Deck'
            ],
            videoLoader: [
                'Sharing meals, building bonds', 'SSM Deck'
            ],
            virtualLoader: [
                'subtltle Virtual 3', 'SSM Deck'
            ],
            virtual: [
                `${lL}texture3.jpg`, 'SSM Deck'
            ],
            infoModal: 'missileDeck',
            hotspots: [
                {
                    id: 'harpoonMissile',
                    action: 'img',
                    position: {
                        x: -300.57678963527087,
                        y: 170,
                        z: 320.85338869350517
                    },
                    rotation: {
                        y: -0.75,
                        z: 4.34
                    },
                    scale: 120
                }, {
                    id: 'launchAndRecoverySystem',
                    action: 'img',
                    position: {
                        x: -420.55267011189045,
                        y: 235,
                        z: -5.886910390685927
                    },
                    rotation: {
                        y: 4.5,
                        z: 4.7
                    },
                    scale: isMobile ? 90 : 110
                }, {
                    id: 'rigidHullBoat​',
                    action: 'img',
                    position: {
                        x: -380,
                        y: -105,
                        z: -162.0911358395032
                    },
                    rotation: {
                        y: -1.13,
                        z: 5.14
                    },
                    scale: 110
                }, {
                    id: 'whiteheadTorpedo',
                    action: 'img',
                    position: {
                        x: 225.0586266981178,
                        y: 15,
                        z: -425.58522326544823
                    },
                    rotation: {
                        y: 3.450,
                        z: 0.5
                    },
                    scale: 120
                },
            ],
            // sound: [`${sL}inner.mp3`],
            thumb: [`${tB}3.jpg`, 'SSM Decks']
        },
        // Bridge
        {
            video: [
                `${cL}cutscene-3.mp4`, true, 'Bridge'
            ],
            videoLoader: [
                'The heart of the ship', 'Bridge'
            ],
            virtualLoader: [
                'The heart of the ship', 'Bridge'
            ],
            virtual: [
                `${lL}texture4.jpg`, 'Bridge'
            ],
            infoModal: 'bridge',
            hotspots: [
                {
                    id: 'integratedBridgeConsoles',
                    action: 'img',
                    position: {
                        x: -390,
                        y: -19,
                        z: 250
                    },
                    rotation: {
                        y: -1.7500000000000012,
                        z: 4.7
                    },
                    scale: 150
                },
                {
                    id: 'electronicChartDisplayAndInformationSystem​',
                    action: 'img',
                    position: {
                        x: -390.26732987961395,
                        y: -40,
                        z: -420
                    },
                    rotation: {
                        y: 4.000000000000002,
                        z: 1.5
                    },
                    scale: 120
                },
                {
                    id: 'portableBridgeStation',
                    action: 'img',
                    position: {
                        x: -215,
                        y: -25,
                        z: 430
                    },
                    rotation: {
                        y: -0.5,
                        z: 4
                    },
                    scale: 90
                },
                {
                    id: 'bridgeEngineeringLargeScreen',
                    action: 'img',
                    position: {
                        x: 450,
                        y: -45,
                        z: -190
                    },
                    rotation: {
                        y: 1.30000000000000003,
                        z: 1.5
                    },
                    scale: 170
                }, {
                    id: 'asterMissile',
                    action: 'img',
                    position: {
                        x: -439.74142454360356,
                        y: -1.53472755155493275,
                        z: -172.8235923918723
                    },
                    rotation: {
                        y: -1.55,
                        z: 4.7
                    },
                    scale: 150
                }, {
                    id: 'melaraSuperRapidGun',
                    action: 'vid',
                    position: {
                        x: -461.63825729739483,
                        y: -1.53472755155493275,
                        z: 67.67803358382687
                    },
                    rotation: {
                        y: -1.55,
                        z: -1.5
                    },
                    scale: 150
                }, {
                    id: 'stabilisedNavalGunSystems',
                    action: 'vid',
                    position: {
                        x: 115.86947164714918,
                        y: -45,
                        z: 475.7000857188365
                    },
                    rotation: {
                        y: -0.25,
                        z: 3.14
                    },
                    scale: 90
                }
            ],
            // sound: [`${sL}inner.mp3`],
            thumb: [`${tB}4.jpg`, 'Bridge']
        },
        {
            video: [
                `${cL}cutscene-4.mp4`, true, 'Combat Information Centre'
            ],
            videoLoader: [
                'The heart of the ship', 'Combat Information Centre'
            ],
            virtualLoader: [
                'The heart of the ship', 'Combat Information Centre'
            ],
            virtual: [
                `${lL}texture5.jpg`, 'Combat Information Centre'
            ],
            infoModal: 'cic',
            hotspots: [
                {
                    id: 'commandCluster',
                    action: 'img',
                    position: {
                        x: -370,
                        y: -75,
                        z: 300
                    },
                    rotation: {
                        y: -1.850000000000005,
                        z: -1.9
                    },
                    scale: 120
                }, {
                    id: 'commandManagementSystem',
                    action: 'img',
                    position: {
                        x: 355,
                        y: -75,
                        z: 250
                    },
                    rotation: {
                        y: 0.68,
                        z: 2.7
                    },
                    scale: 170
                }, {
                    id: 'pictureCluster',
                    action: 'img',
                    position: {
                        x: 180,
                        y: -50,
                        z: 440
                    },
                    rotation: {
                        y: -0.1500000000000006,
                        z: 2.9
                    },
                    scale: 110
                }, {
                    id: 'strikeDefenceCluster',
                    action: 'img',
                    position: {
                        x: -155,
                        y: -10,
                        z: 440
                    },
                    rotation: {
                        y: 1.6,
                        z: -2.6
                    },
                    scale: 90
                },
            ],
            // sound: [`${sL}outer.mp3`],
            thumb: [
                `${tB}5.jpg`, 'CIC'
            ],
            end: [`${cL}cutscene-5.mp4`, true, 'Combat Information Centre']
        }
	]
}

