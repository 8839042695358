import React from 'react'
import { SceneSelector, Tile } from './style';

function MobileNavigation({ storyBoard, handleUpdateScene, toggleNavigation, isMobileNavigationActive, currentScene }){
    return (
        <SceneSelector isMobileNavigationActive={isMobileNavigationActive}>
          <ul>
            {
              storyBoard.map((scene, index) => 
                <Tile isActive={currentScene === index} key={index} onClick={() => handleUpdateScene(index)}>
                    <img alt='' src={require(`../../assets/${scene.thumb[0]}`)}/>
                    <span>{scene.thumb[1]}</span>
                </Tile>
              )
            }
          </ul>
        </SceneSelector>
    )
}

export default MobileNavigation