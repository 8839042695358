import * as THREE from 'three';
import React, { useRef, useEffect, useState, Suspense } from 'react';
import { Canvas, useLoader } from 'react-three-fiber';
import { fbButton, tw, whatsapp } from 'vanilla-sharing';

import { getItem, setItem } from './utils/localStorage';

import fetchStoryBoard from './data/storyBoard';
import { lightBoxData } from './data/lightBoxData';
import { menuData, socialData } from './data/menuData';

import Navigation from './components/Navigation';
import MobileNavigation from './components/MobileNavigation';
import Header from './components/Header';
import Menu from './components/Menu';
import LightBox from './components/LightBox';
import Tutorial from './components/Tutorial';
import VideoPlayer from './components/videoPlayer';
import {SkipContinueButton, ButtonWithLabel} from './components/Buttons'
import Loading from './components/Loading'
import Controls from './components/Controls'
import Pin from './components/Pin'
import FullscreenLightBox from './components/FullscreenLightBox'
import Drawer from './components/Drawer'
import MegaMenu from './components/MegaMenu'

import useWindowSize from "./hooks/useWindowSize";

import OrientationImg from './assets/images/icons/orientation.png'

import './App.css';

function Scene({currentScene, storyBoard, onHotspotClick}) {
  const map = useLoader(THREE.TextureLoader, require(`./assets/textures/texture${currentScene}.jpg`))
  const ref = useRef();
  const [hotspots, setHotspots] = useState([])

  useEffect(() => {
    setHotspots(storyBoard[currentScene - 1].hotspots);
  }, [currentScene, storyBoard])

  map.wrapS = THREE.RepeatWrapping;
  map.repeat.x = -1;
  map.offset.x = 1.5;

  return (
    <mesh ref={ref} position={[0, currentScene === 2 ? -65 : 80, 0]} >
      {
        hotspots.map((hotspot, index) => {
          return <Pin 
            key={index} 
            action={hotspot.action}
            rotation={[1.6, 0, hotspot.rotation.z ? hotspot.rotation.z : 0 ]}
            position={[hotspot.position.x, hotspot.position.y, hotspot.position.z]}
            hotspotId={hotspot.id}
            handleClick={onHotspotClick}
          />
        })
      }
      <sphereGeometry attach="geometry" args={[500, 200, 20]} />
      <meshBasicMaterial attach="material" map={map} side={THREE.BackSide} />
    </mesh>
  )
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isVideoActive, setIsVideoActive] = useState(true);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [currentScene, setCurrentScene] = useState(0);
  const [storyBoard, setStoryBoard] = useState([]);
  const [isLightBoxActive, setIsLightBoxActive] = useState(false);
  const [lightBoxContent, setLightBoxContent] = useState("");
  const [hasShownTutorial, setHasShownTutorial] = useState(getItem('hasShownTutorial') || false);
  const [isNavigationActive, setIsNavigationActive] = useState(false);
  const [isMobileNavigationActive, setIsMobileNavigationActive] = useState(false);
  const windowInfo = useWindowSize();
  
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [hasTourEnded, setHasTourEnded] = useState(false);

  useEffect(() => {
    setStoryBoard(fetchStoryBoard());
    setIsNavigationActive(true);
    resetTimeout();

    if(currentScene >= 5 && !isVideoActive) {
      setIsMenuActive(true)
      setHasTourEnded(true)
    }

  }, [currentScene, isVideoActive, hasShownTutorial,])

  const handleLoading = () => setIsLoading(false);

  const resetTimeout = () => {
    setTimeout(() => setIsNavigationActive(false), 2500)
  }

  const onSkip = () => setIsVideoActive(false);

  const onContinue = () => {
    setIsVideoActive(true);
    setCurrentScene(currentScene + 1);
  }

  const handleUpdateScene = scene => {
    setIsVideoActive(false);
    setCurrentScene(scene);
    setIsMobileNavigationActive(false);
  }

  const onRestartTour = () => {
    setCurrentScene(0);
    setIsVideoActive(true);
    setIsMenuActive(false);
    setHasTourEnded(false);
  }

  const onVideoEnd = () => setIsVideoActive(false);

  const toggleNavigation = () => {
    setIsNavigationActive(!isNavigationActive);
    resetTimeout();
  }

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive)
    setIsMobileNavigationActive(false)
  };

  const toggleMobileNavigation = () => {
    setIsMobileNavigationActive(!isMobileNavigationActive)
    setIsMenuActive(false)
  };

  const toggleLightBox = id => {
    if(lightBoxData[id]) {
      setLightBoxContent(lightBoxData[id]);
    }
    
    setIsLightBoxActive(!isLightBoxActive);
  }

  const onDismissTutorial = () => {
    setItem('hasShownTutorial', true);
    setHasShownTutorial(true)
  };

  const requestFullscreen = () => {

    const element = document.body;

    const fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled;

    if(!windowInfo.width > 900 || !fullscreenEnabled){
      setIsFullscreen(true)
      return;
    } else if(element.requestFullscreen) {
      element.requestFullscreen();
    } else if(element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if(element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if(element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    setIsFullscreen(true)
  }

  const onShare = target => {
    const url = window.location.href;
    const title = document.title;

    switch (target) {
      case 'fb':
          fbButton({
              url
          });
          break;
      case 'whatsapp':
          whatsapp({
              url,
              title,
          });
          break;
      case 'mail':
          // console.log('email share');
          break;
      default: 
          return;
      }
  }
  
  return (
    <>
      <FullscreenLightBox isVisible={!windowInfo.isInLandscape}>
        <h1>Please rotate your device</h1>
        <p>This experience is best viewed in landscape orientation.</p>
        <img src={OrientationImg} alt=''/>
      </FullscreenLightBox>

      <FullscreenLightBox isVisible={windowInfo.width < 900 && !isFullscreen}>
          <h1>Before We Begin</h1>
          <p>To give you the best experience, the Virtual Tour requires your permission to play videos in full screen mode.</p>
          <button onClick={() => requestFullscreen()} aria-label="Accept">ACCEPT</button>
      </FullscreenLightBox>

      { 
        isLoading
          ? <Loading handleLoading={handleLoading}/>
          : isVideoActive
            ? <VideoPlayer currentScene={currentScene} onVideoEnd={onVideoEnd}/>
            : <Canvas 
                camera={{ 
                  position: [0, 0, 0],  
                  fov: 45,  
                  far: 1100, 
                  near: 200, 
                  aspect: windowInfo.width/windowInfo.height,
                  pixelRatio: window.devicePixelRatio,
                  target: new THREE.Vector3(0, 0, 0)
                }}>
                <Controls currentScene={currentScene} />
                <Suspense fallback={null}>
                  {
                    currentScene < 5
                    ?  <Scene currentScene={currentScene + 1} storyBoard={storyBoard} onHotspotClick={toggleLightBox}/>
                    : null
                  } 
                </Suspense>

                <ambientLight color={0xffffff} intensity={1} />
              </Canvas>
      }

      {
        !isVideoActive && currentScene === 0 && !hasShownTutorial
        ? <Tutorial active={!hasShownTutorial} onDismissTutorial={onDismissTutorial} />
        : null
      }

      <Navigation currentScene={currentScene} toggleNavigation={toggleNavigation} isNavigationActive={isNavigationActive} storyBoard={storyBoard} handleUpdateScene={handleUpdateScene} />
      <MobileNavigation currentScene={currentScene} isMobileNavigationActive={isMobileNavigationActive} storyBoard={storyBoard} handleUpdateScene={handleUpdateScene} />

      <LightBox data={lightBoxContent} toggleLightBox={toggleLightBox} isLightBoxActive={isLightBoxActive}/>

      <MegaMenu isMegaMenuActive={isMenuActive} menuData={menuData} socialData={socialData} currentScene={currentScene}/> 
      <Menu isMenuActive={isMenuActive} onRestartTour={onRestartTour} onShare={onShare} currentScene={currentScene}/>
      
      <Header 
        toggleMenu={toggleMenu} 
        isVideoActive={isVideoActive} 
        toggleLightBox={toggleLightBox} 
        sceneData={storyBoard[currentScene]} 
        toggleMobileNavigation={toggleMobileNavigation} 
        isLoading={isLoading} 
        isMobileNavigationActive={isMobileNavigationActive} 
        hasTourEnded={hasTourEnded} 
        isMenuActive={isMenuActive} 
      /> 

      <Drawer iconType='share' isVideoActive={isVideoActive}> 
        <ButtonWithLabel type='fb' clickHandler={() => onShare('fb')}></ButtonWithLabel>
        <ButtonWithLabel type='mail' clickHandler={() => onShare('mail')}></ButtonWithLabel>
        <ButtonWithLabel type='whatsapp' clickHandler={() => onShare('whatsapp')}></ButtonWithLabel>
      </Drawer>

      {
        !isLoading 
        ? <>
            <SkipContinueButton slideIn={isVideoActive} clickHandler={onSkip}>Skip</SkipContinueButton>
            <SkipContinueButton slideIn={!isVideoActive} clickHandler={onContinue}>Continue</SkipContinueButton>
          </>
        : null
      }
      
    </>
  )
}

export default App;