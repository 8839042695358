/* eslint-disable */

// import
let path = '/assets/images/hotspot/scene';

// fetch story board
export const lightBoxData = {
	/// SCENE 1 - Mission Bay
		mainPassageway: [
			// head
			"Main Passageway",
			// body
			"<p>The main passageway connects to the Galley, the Machinery Control Room, the stairwell to the bridge, and the stairwell to the Combat Information Centre and Radio Room. The convenience of the main passageway allows ease of access for emergencies, such as firefighting and damage control at harbour and action stations when underway.​</p>"
		],
		gunBay: [
			// head
			"Gun Bay",
			// body
			`<p>The gun bay is located at the bow of the ship. It is where the frigate loads and operates the 76mm Oto Melara Super Rapid Gun, to deal with surface threats. The gun uses a revolving feed drum that allows automatic reloading of new rounds with each round fired.​</p>
			<img src='${path}1/Gun_Bay_01.jpg'/>
			<img src='${path}1/Gun_Bay_02.jpg'/>`
		],
		medicalCentre: [
			// head
			"Medical Centre",
			// body
			`<p>Climb the stairwell here and you will arrive at the Medical Centre. It is supported by Navy Medical Officers and medics, and equipped with medical facilities such as quarantine compartments or recuperating rooms to provide rapid and effective medical response at sea.</p>
			<img src='${path}1/Medical_Centre_01.jpg'/>`
		],
		activeLowFrequencyTowedSonar: [
			// head
			"Active Low Frequency Towed Sonar",
			// body
			`<p>The Active Low Frequency Towed Sonar enables detection of adversary submarines and other underwater threats at extended range and depth.​</p>
			<div>
				<img src='${path}1/Active_Low_Frequency_Towed_Sonar_01.jpg' class='hullboat-1'/>
				<img src='${path}1/Active_Low_Frequency_Towed_Sonar_02.jpg' class='hullboat-2'/>
			</div>`
		],
		machineryControlRoom: [
			// head
			"Machinery Control Room",
			// body
			`<p>The Machinery Control Room (MCR) is the main nerve centre which monitors and manages the engineering and propulsion system. By leveraging advanced automation, our lean three-man team of engineers can control and monitor any machinery from the MCR itself during normal cruising operations when out at sea.​</p>
			<img src='${path}1/Machinery_Control_Room_01.jpg' class='hullboat-1'/>`
		],
	/// SCENE 2 - Hangar
		helipad: [
			// head
			"Hangar",
			// body
			"<p>The hangar is fully equipped with helicopter support and maintenance systems that are capable of embarking up to a medium-sized helicopter such as the Sikorsky S-70B Seahawk naval helicopter. During helicopter operations, the hangar is also equipped with specialised fire-fighting equipment that allows the crew to respond efficiently and effectively to contingencies.​</p>"
		],
		"helicopterControlRoom​": [
			false,
			// body
			`<img src='${path}2/Helicopter_Control_Room_01.jpg' class='img-left' />
			<div class='content-right'>
				<h1>Helicopter Control Room​</h1>
				
				<p>The helicopter control room is the control centre for all flight deck operations, and houses controls for all aviation equipment on board. The room is also equipped with displays that allow constant monitoring of navigational and meteorological data, which are important safety factors during helicopter operations.​</p>
			</div>`,
			"text-left imgLeft-textRight"
		],
		"helicopterLandingAids​": [
			false,
			// body
			`<img src='${path}2/Helicopter_Landing_Aids_01.jpg' class='img-left' />
			<div class='content-right'>
				<h1>Helicopter Landing Aids​</h1>
				
				<p>The frigate’s hangar face and flight deck are equipped with a sophisticated system of intensity-adjustable lights that serve as visual landing aids during helicopter operations. This enables the frigate to facilitate helicopter landing and take-off under all lighting conditions, including night operations.</p>
			</div>`,
			"text-left imgLeft-textRight"
		],
		"rapidSecuringDevice​": [
			// head
			"Rapid Securing Device​",
			// body
			`<p>The rapid securing device is part of the Aircraft Ship Integrated Securing and Transport (ASIST) system in the hangar and flight deck, which helps to capture and release the helicopter probe as the helicopter lands or takes off. The device can also transport the helicopter in and out of the hangar.</p>
			<img src='${path}2/Rapid_Securing_Device_01.jpg'/>`
		],
		"seahawkNavalHelicopter​": [
			// head
			"S-70B Seahawk Naval Helicopter​",
			// body
			`<p>The Sikorsky S-70B Seahawk is a naval helicopter equipped with advanced anti-surface and anti-submarine warfare sensors and weapons, which boosts the frigate’s ability to detect and engage enemy ships and submarines, allowing her to see further and strike faster.​</p>
			<img src='${path}2/S-70B_Seahawk_Naval_Helicopter_01.jpg'/>`
		],
		"hangarDoor​": [
			// head
			"Hangar Door​",
			// body
			`<p>The hangar door separates personnel and equipment during live helicopter operations for safety.</p>
			<img src='${path}2/Hangar_Door_01.jpg'/>
			<img src='${path}2/Hangar_Door_02.jpg'/>
			`
		],
		/// SCENE 3 - SSM Deck
		missileDeck: [
			// head
			"Surface-to-surface Missile Deck and Torpedo Deck",
			// body
			"<p>The frigate has two decks: surface-to-surface missile deck and torpedo deck. The surface-to-surface missile deck is where the Boeing Harpoon Surface-to-Surface Missiles are launched from. The torpedo deck is where she fires the Whitehead 244S Torpedoes. During normal cruising, the decks may also serve as a mustering point during contingency drills such as life-raft station or emergency station. ​</p>"
		],
		harpoonMissile: [
			false,
			// body
			`<img src='${path}3/Harpoon_Missile_01.jpg' class='img-left' />
			<div class='content-right'>
				<h1>Harpoon Missile​</h1>
				
				<p>The Boeing Harpoon Surface-to-Surface Missile serves as the frigate’s primary long-range anti-surface warfare weapon, and is able to engage surface threats in excess of 100km.​</p>
			</div>`,
			"text-left imgLeft-textRight"
		],
		launchAndRecoverySystem: [
			// head
			"Launch and Recovery System​",
			// body
			`<p>The frigate’s surface-to-surface missile deck may also be modified to house an Unmanned Surface Vessel launch and recovery system, which consists of a crane and cradles that can store up to two boats, it facilitates the loading and unloading of small surface craft from the surface-to-surface missile deck into sea. ​</p>
			<img src='${path}3/Launch_and_Recovery_System_01.jpg'/>`
		],
		'rigidHullBoat​': [
			// head
			"Rigid Hull Boat",
			// body
			`<p>The frigate is designed to launch and recover a medium-sized Rigid Hull Boat (RHB) from her boat deck located at the starboard side of the ship. RHB operations are essential to the frigate, and can be deployed for boarding operations.</p>
			<img src='${path}3/Rigid_Hull_Boat_01.jpg'/>
			`
		],
		whiteheadTorpedo: [
			false,
			// body
			`<img src='${path}3/Whitehead_Torpedo_01.jpg' class='img-left' />
			<div class='content-right'>
				<h1>Whitehead Torpedo​</h1>
				
				<p>The frigate is equipped with two triple-barrelled lightweight torpedo launchers armed with Whitehead A244S Torpedoes. This underwater capability is aided by the active low frequency towed sonar underwater detection system and is complemented by the underwater detection and stand-off capabilities of the naval helicopter.​</p>
			</div>`,
			"text-left imgLeft-textRight"
		],
	/// SCENE 4 - The ICC
		bridge: [
			// head
			"Bridge",
			// body
			"<p>The frigate’s integrated bridge system incorporates many advanced features that allow the bridge team to fulfil their primary role in navigating the ship, while controlling and monitoring ship-wide systems simultaneously. Through the combat management and damage control systems, the bridge maintains a high level of integration with the Combat Information Centre and the machinery control room, a feature integral in enhancing situational awareness and operational synergy on board the frigate.​</p>"
		],
		integratedBridgeConsoles: [
			// head
			"Integrated Bridge Consoles​",
			// body
			`<p>The Integrated Bridge System is monitored and operated through the Integrated Bridge Consoles that are able to interchangeably display five different applications – from controlling and monitoring the ship’s systems to using navigational aids. Such high levels of automation also allow the bridge to achieve a lean four-man bridge team during cruising watch.​</p>
			<img src='${path}4/Integrated_Bridge_Consoles_01.jpg'/>`
		],
		"electronicChartDisplayAndInformationSystem​": [
			// head
			"Electronic Chart Display and Information System​",
			// body
			`<p>The electronic chart display and information system is the frigate’s primary means of navigation. This automation provides navigators with higher accessibility to information and efficiency in relaying crucial information to the Officer-of-the-Watch.​</p>
			<img src='${path}4/Electronic_Chart_Display_and_Information_System_01.jpg'/>`
		],
		portableBridgeStation: [
			false,
			// body
			`<img src='${path}4/Portable_Bridge_Station_01.jpg' class='img-left' />
			<div class='content-right'>
				<h1>Portable Bridge Station​​</h1>
				
				<p>The portable bridge station is a portable manual control station for the propulsion and steering systems. It is used to control the ship when moving at low speeds, and during the entry and exit of harbour.</p>
			</div>`,
			"text-left imgLeft-textRight"
		],
		bridgeEngineeringLargeScreen: [
			// head
			"Bridge Engineering Large Screen​",
			// body
			`<p>The bridge’s engineering large screen is able to display the ship control management and monitoring system and CCTV, which allows the Officer-of-the-Watch and the Commanding Officer to visually monitor and supervise work areas such as the flight deck and quarterdeck during operations.</p>
			<img src='${path}4/Bridge_Engineering_Large_Screen_01.jpg'/>`
		],
		asterMissile: [
			false,
			// body
			`<img src='${path}4/Aster_Missile_01.jpg' class='img-left' />
			<div class='content-right'>
				<h1>Aster Missile​</h1>
				
				<p>The surface-to-air missile deck is located between the bridge and the bow of the frigate. Here, the ship fires the MBDA Aster active radar-homing anti-missile missiles to engage air threats. This anti-air warfare capability, supported by the multi-function radar’s 3D surveillance of more than 200km, allows the frigate to engage air threats and maintain air defence for friendly forces.​</p>
			</div>`,
			"text-left imgLeft-textRight"
		],
		melaraSuperRapidGun: [
			// head
			"76mm Oto Melara Super Rapid Gun​",
			// body
			`<p>Located in front of the missile deck, the 76mm Oto Melara Super Rapid Gun is the frigate’s main warfare gun. It is capable of dealing with air and surface threats and can be fired with high accuracy at a sustained rate. </p>
			<video class='modal_video' width="640" height="360" preload="metadata" playsinline controls poster="${path}4/76mm_Oto_Melara_Super_Rapid_Gun_01.jpg" autobuffer="true">
				<source src="${path}4/76mm_Oto_Melara_Super_Rapid_Gun_01.mp4" type="video/mp4">
			</video>`
		],
		stabilisedNavalGunSystems: [
			// head
			"Typhoon MK II 25mm Stabilised Naval Gun Systems​",
			// body
			`<p>The Typhoon MK II 25mm Stabilised Naval Gun Systems can be operated from here in the Bridge, or from the CIC. These are gyro-stabilised guns situated at the port and starboard of the frigate, and are effective countermeasures against small boat threats at close range.​</p>
			<video class='modal_video' width="640" height="360" preload="metadata" playsinline controls poster="${path}4/Typhoon_MK_II_25mm_Stabilised_Naval_Gun_Systems_01.jpg" autobuffer="true">
				<source src="${path}4/Typhoon_MK_II_25mm_Stabilised_Naval_Gun_Systems_01.mp4" type="video/mp4">
			</video>`
		],
	/// SCENE 5 - Helipad
		// combat information centre
		cic: [
			// head
			"Combat Information Centre",
			// body
			"<p>The Combat Information Centre, or the CIC, is the nerve centre of the frigate’s warfighting capabilities. Here, the CIC team makes sense of information consolidated from the frigate’s advanced sensors, and drives the warfighting effort.​</p>"
		],
		commandCluster: [
			// head
			"Command Cluster",
			// body
			`<p>The command cluster provides tactical directions and decisions while the other functional clusters – picture, strike/defence and underwater – provide tactical support with their operation of sensors and weapon systems.​</p>
			<img src='${path}5/Command_Cluster_01.jpg'/>`
		],
		commandManagementSystem: [
			// head
			"Command Management System​",
			//body
			`<p>The combat management system allows the CIC team to exercise synergistic command and control of the frigate’s sensors and weapon systems. ​</p>
			<img src='${path}5/Command_Management_System_01.jpg'/>`
		],
		pictureCluster: [
			// head
			"Picture Cluster​",
			//body
			`<p>Command and control of the various sensor and weapon systems on board and managed by the picture cluster, using an indigenously designed combat management system.​</p>
			<img src='${path}5/Picture_Cluster_01.jpg'/>`
		],
		strikeDefenceCluster: [
			// head
			"Strike/Defence Cluster",
			// body
			`<p>Here at the strike/defence cluster, a crew can control the frigate’s array of weapons such as the Aster and Harpoon missiles and the A-gun, to not only defend the ship but also bear arms on any adversary. The crew here works closely with the picture cluster to identify, locate and eliminate any threat with the appropriate weapon.​</p>
			<img src='${path}5/Strike_defence_Cluster_01.jpg'/>`
		],
};
