import React from 'react'
import { StyledLightBox } from './style'
import LookAround from '../../assets/images/icons/lookaround.png';

function Tutorial({active, onDismissTutorial}) {
    return (
        <StyledLightBox isActive={active}>
            <img src={LookAround} alt="" />
            <h1>Look Around & Explore</h1>
            <p>Hold and drag to look around. Click on the hotspots to learn more.</p>
            <button onClick={() => onDismissTutorial()}>Dismiss</button>
        </StyledLightBox>
    )
}

export default Tutorial