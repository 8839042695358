import React from 'react'
import { StyledSkipContinueButton, StyledHamburgerButton, StyledButtonWithLabel } from './style'

const SkipContinueButton = (props) => {

    const { slideIn, clickHandler } = props;

    return <StyledSkipContinueButton slideIn={slideIn} onClick={() => clickHandler()}>
        { props.children }
    </StyledSkipContinueButton>
}

const HamburgerButton = (props) => {

    const handleClick = () => {
        props.clickHandler();
    }

    return <StyledHamburgerButton open={props.isActive} {...props} onClick={() => handleClick()}>
        <div/>
        <div/>
        <div/>
    </StyledHamburgerButton>
}

const ButtonWithLabel =({ type, clickHandler, label }) =>
    <StyledButtonWithLabel type={type} onClick={() => clickHandler()}>{label}</StyledButtonWithLabel>

export {
    SkipContinueButton,
    HamburgerButton,
    ButtonWithLabel
};