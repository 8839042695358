import styled from 'styled-components'

const StyledMenu = styled.div`
    position: fixed;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 7;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out; 
    visibility: ${props => props.isMenuActive ? 'visible' : 'hidden'};
    opacity: ${props => props.isMenuActive ? '1' : '0'};
    padding: 4rem;
    box-sizing: border-box;

    // @media (min-width: 900px) {
    //     display: none;
    // }
    
    h1 {
        color: #ffffff;
        margin-top: 4rem;
    }
`

const ThumbnailSet = styled.ul`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
`

const Thumbnail = styled.li`
    width: 33%; 
    height: auto;

    @media (min-width: 900px) {
        height: 240px; 
        width: 490px;
    }

    list-style: none;
    background-image: url(${props => props.bgSrc});
    background-size: cover; 
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; 
    flex-direction: column;
    color: #fff;
    cursor: pointer;
    padding: 15px 10px;

    &:last-child {
        margin-right: 0;
    }

    img {
        height: 58px;
        width: 57.5px;

        @media (min-width: 900px) {
            height: auto; 
            width: auto;
        }
    }

    span {
        margin-top: 5px; 
        font-size: 16px;

        @media (min-width: 900px) {
            font-size: 18px;
        }
    }
`

const Share = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center;

    p { color: #fff; margin-top: 0; margin-bottom: 5px; }

    div { 
        display: flex;
        span { 
            margin-right: 2rem; 
            
            :last-child {
                margin-right: 0;
            }
        }
    }
`;

const ButtonSet = styled.div`
    display: flex;
    justify-content: space-evenly;
`

const Container = styled.div`
    display: flex;
    justify-content: space-around; 
    width: 100%;
    align-items: flex-end;

    @media (min-width:900px){
        width: 60%;
        margin-top: 35px;
    }
`

export {
    StyledMenu,
    ThumbnailSet,
    Thumbnail,
    ButtonSet,
    Share,
    Container
}