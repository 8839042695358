import React from 'react'
import { ButtonWithLabel } from '../Buttons';
import { StyledMenu, /*ButtonWithLabel,*/ ThumbnailSet, Thumbnail, ButtonSet, Share, Container } from './style'

const thumbnailSet = [
    {
        icon: 'icons/mission.png',
        label: 'Our Mission',
        background: 'thumbs/mission.jpg',
        link: '#'
    }, {
        icon: 'icons/ship.png',
        label: 'Formidable-class Frigate',
        background: 'thumbs/lmv.jpg',
        link: '#'
    }, {
        icon: 'icons/stories.png',
        label: 'On Deployment',
        background: 'thumbs/stories.jpg',
        link: '#'
    }, 
]

function Menu({ onRestartTour, isMenuActive, onShare, currentScene }) {

    const onThumbnailClick = link => window.open(link);

    return (
        <StyledMenu isMenuActive={(isMenuActive && currentScene === 5)  || (isMenuActive && window.innerWidth < 900)}>
            <h1>More About Life in the Navy</h1>
            <ThumbnailSet>
                {
                    thumbnailSet.map((thumbnail, index) => 
                        
                        <Thumbnail onClick={() => onThumbnailClick(thumbnail.link)}key={index} bgSrc={require(`../../assets/images/${thumbnail.background}`)}>
                            <img src={require(`../../assets/images/${thumbnail.icon}`)} alt=""/>
                            <span>{thumbnail.label}</span>
                        </Thumbnail>
                    )
                }
            </ThumbnailSet>

            <Container>
                <Share>
                    <p>Share</p>
                    <div>
                        <ButtonWithLabel type="fb" clickHandler={() => onShare('fb')}></ButtonWithLabel>
                        <ButtonWithLabel type="mail" clickHandler={() => onShare('mail')}></ButtonWithLabel>
                        <ButtonWithLabel type="whatsapp" clickHandler={() => onShare('whatsapp')}></ButtonWithLabel>
                    </div>
                </Share>

                <ButtonSet>
                    <ButtonWithLabel type="restart" clickHandler={() => onRestartTour()} label={'Restart tour'}></ButtonWithLabel>
                </ButtonSet>
            </Container>

        </StyledMenu>
    )
}

export default Menu