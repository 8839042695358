import * as THREE from 'three';
import React, { useState } from 'react';
import { useLoader } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";


function Pin(props) {
    const { action, hotspotId, handleClick } = props;  
    const x = useLoader(GLTFLoader, require(`../../assets/models/hotspot_${action === 'img' ? 'image' : 'play'}.glb`));
    const nodes = x.nodes; 

    const [ clicked, setClicked ] = useState(false)
    
    const handleHotspotClick = () => {
      setClicked(true); 
      handleClick(hotspotId);      
    }

    return (
      <mesh 
        visible 
        {...props} 
        geometry={nodes.Circle.geometry} 
        scale={window.innerWidth > 900 ? [15, 15, 15] : [20, 20, 20]}
        onPointerDown={e => handleHotspotClick()}
        onPointerOver={e => document.getElementsByTagName('body')[0].style.cursor = 'pointer'}
        onPointerOut={e => document.getElementsByTagName('body')[0].style.cursor = 'initial'}
      >
        <meshStandardMaterial attach="material" {...x.__$[1].material} side={THREE.DoubleSide} metalness={clicked ?  0.5 : 0}/> 
      </mesh>
    );
}

export default Pin;