import * as THREE from 'three';
import React, { useRef, useEffect, useState} from 'react'
import { useFrame, extend, useThree } from 'react-three-fiber';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import useWindowSize from "../../hooks/useWindowSize";

extend({ OrbitControls });

function Controls({props, currentScene}) {
    const ref = useRef();
    const { camera, gl } = useThree();
    const distance = 475;
    const [lat, setLat] = useState(0);
    const [phi, setPhi] = useState(0);
    const [theta, setTheta] = useState(0);
    const lon = 0;
    const [azimuthAngle, setAzimuthAngle] = useState({ min: -Infinity, max: Infinity })
    const windowInfo = useWindowSize();
  
    useEffect(() => {
      setLat(Math.max(-85, Math.min(85, lat)));
      setPhi(THREE.Math.degToRad(90 - lat));
      setTheta(THREE.Math.degToRad(lon));

      camera.position.x = distance * Math.sin(phi) * Math.cos(theta);
      camera.position.y = -(distance * Math.cos(phi)); // negate
      camera.position.z = distance * Math.sin(phi) * Math.sin(theta);
      camera.aspect = windowInfo.width/windowInfo.height
  
      if(currentScene + 1 === 5) {
        setAzimuthAngle({ min: 2, max: 3.14 })
      } else {
        setAzimuthAngle({ min: -Infinity, max: Infinity })
      }
    }, [lat, phi, theta, lon, camera.position, currentScene])
  
    useFrame(() => ref.current.update())
  
    return <orbitControls 
            ref={ref} 
            // {...props} 
            enableZoom={false}
            enablePan={false} 
            enableDamping 
            dampingFactor={0.2} 
            rotateSpeed={-0.5}
            args={[camera, gl.domElement]} 
            minAzimuthAngle={azimuthAngle.min} 
            maxAzimuthAngle={azimuthAngle.max} 
          />
  }

export default Controls