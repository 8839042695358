import React from 'react'
import { MegaMenuWrapper, SocialLinksWrapper, MenuWrapper, Column, Header, StyledLinks } from './style';

function MegaMenu({ isMegaMenuActive, menuData, socialData, currentScene }) {

    const Links = ({ linkData }) => {
        const path = 'https://www.mindef.gov.sg/web/portal/navy'

        return <StyledLinks>
            {
                linkData.links.map((link, j) => 
                    <li key={j}>
                        <a target="_blank" rel="noopener noreferrer" className={link[2]} href={`${path}${linkData.base}${link[1]}`}>{link[0]}</a>
                    </li>
                )
            }
        </StyledLinks>
    }

    return (
        <MegaMenuWrapper isMegaMenuActive={isMegaMenuActive && currentScene !== 5 && window.innerWidth > 900}>
            <p>{socialData.copy}</p> 
            <SocialLinksWrapper>
                {
                    socialData.links.map((link, index) => 
                        <a href={link[1]} key={index}>
                            <img src={socialData.base + link[0]} alt={""}/>
                        </a>
                    )
                }
            </SocialLinksWrapper>
            <MenuWrapper>
                {
                    menuData.map((menuItem, index) => 
                        <Column key={index}>
                            <Header href={menuItem.base}>{menuItem.title}</Header>
                            <Links linkData={menuItem}/>
                        </Column>
                    )
                }
            </MenuWrapper>
        </MegaMenuWrapper>
    )
}

export default MegaMenu