import { useState, useEffect } from 'react';

function useWindowSize() {
    const isClient = typeof window === 'object';
  
    function getSize() {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);

      return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
            isInLandscape: isClient ? window.innerWidth > window.innerHeight : false
        };
    }
  
    const [windowSize, setWindowSize] = useState(getSize);
  
    useEffect(() => {
      if (!isClient) {
        return false;
      }
      
      function handleResize() {
        setWindowSize(getSize());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount
  
    return windowSize;
  }

  export default useWindowSize