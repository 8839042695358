import React, { useEffect,  useRef, useState, useCallback } from 'react'
import { Video, VideoWrapper, Overlay, PlayerControls, Button } from './style';
import { getItem, setItem } from '../../utils/localStorage';

function VideoPlayer({currentScene, onVideoEnd}) {

    const vidRef = useRef();
    
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(getItem('isMuted') || false);

    const videoCallback = useCallback(event => {
        if(isPlaying) onVideoEnd();
    }, [isPlaying, onVideoEnd])

    useEffect(() => {
        setIsPlaying(true);

        vidRef.current.play();
        vidRef.current.muted = isMuted;
        vidRef.current.setAttribute('webkit-playsinline', true);
        vidRef.current.setAttribute('playsinline', true);

    }, [videoCallback, onVideoEnd, isMuted]);

    const onPlayPause = () => {
        if(isPlaying) {
            setIsPlaying(false);
            vidRef.current.pause()
        } else {
            setIsPlaying(true);
            vidRef.current.play()
        }  
    };

    const onMute = () => {
        if(isMuted) {
            vidRef.current.muted = false;
            setIsMuted(false)
            setItem('isMuted', false)
        } else {
            vidRef.current.muted = true;
            setIsMuted(true)
            setItem('isMuted', true)
        }
    }

    return (
        <VideoWrapper>
            <Overlay showOverlay={!isPlaying}>
                <span onClick={() => onPlayPause()}></span>
                <p>Resume</p>
            </Overlay>

            <PlayerControls>
                <Button type="pause" onClick={() => onPlayPause()} className="btn">{isPlaying ? 'Pause' : 'Play'}</Button>
                <Button type="mute" isMuted={isMuted} onClick={() => onMute()} className="btn">{isMuted ? 'Unmute' : 'Mute'}</Button>
            </PlayerControls>

            <Video ref={vidRef} autoPlay controls={false} onEnded={videoCallback}>
                <source src={require(`../../assets/cutScenes/${window.innerWidth < 900 ? 'mobile/' : ''}cutscene-${currentScene}.mp4`)} type="video/mp4" />
            </Video>
        </VideoWrapper>
    )
}

export default VideoPlayer;